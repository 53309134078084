exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-500-index-jsx": () => import("./../../../src/pages/500/index.jsx" /* webpackChunkName: "component---src-pages-500-index-jsx" */),
  "component---src-pages-about-narcolepsy-pathophysiology-index-jsx": () => import("./../../../src/pages/about-narcolepsy/pathophysiology/index.jsx" /* webpackChunkName: "component---src-pages-about-narcolepsy-pathophysiology-index-jsx" */),
  "component---src-pages-comorbidities-risk-cardiovascular-comorbidities-index-jsx": () => import("./../../../src/pages/comorbidities-risk/cardiovascular-comorbidities/index.jsx" /* webpackChunkName: "component---src-pages-comorbidities-risk-cardiovascular-comorbidities-index-jsx" */),
  "component---src-pages-comorbidities-risk-high-sodium-intake-index-jsx": () => import("./../../../src/pages/comorbidities-risk/high-sodium-intake/index.jsx" /* webpackChunkName: "component---src-pages-comorbidities-risk-high-sodium-intake-index-jsx" */),
  "component---src-pages-comorbidities-risk-psychiatric-and-sleep-comorbidities-index-jsx": () => import("./../../../src/pages/comorbidities-risk/psychiatric-and-sleep-comorbidities/index.jsx" /* webpackChunkName: "component---src-pages-comorbidities-risk-psychiatric-and-sleep-comorbidities-index-jsx" */),
  "component---src-pages-comorbidities-risk-risk-index-jsx": () => import("./../../../src/pages/comorbidities-risk/risk/index.jsx" /* webpackChunkName: "component---src-pages-comorbidities-risk-risk-index-jsx" */),
  "component---src-pages-disease-management-treatment-decisions-index-jsx": () => import("./../../../src/pages/disease-management/treatment-decisions/index.jsx" /* webpackChunkName: "component---src-pages-disease-management-treatment-decisions-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-pediatric-patients-burden-of-illness-index-jsx": () => import("./../../../src/pages/pediatric-patients/burden-of-illness/index.jsx" /* webpackChunkName: "component---src-pages-pediatric-patients-burden-of-illness-index-jsx" */),
  "component---src-pages-pediatric-patients-clinical-interview-index-jsx": () => import("./../../../src/pages/pediatric-patients/clinical-interview/index.jsx" /* webpackChunkName: "component---src-pages-pediatric-patients-clinical-interview-index-jsx" */),
  "component---src-pages-pediatric-patients-differential-diagnosis-index-jsx": () => import("./../../../src/pages/pediatric-patients/differential-diagnosis/index.jsx" /* webpackChunkName: "component---src-pages-pediatric-patients-differential-diagnosis-index-jsx" */),
  "component---src-pages-pediatric-patients-patient-and-caregiver-perspectives-index-jsx": () => import("./../../../src/pages/pediatric-patients/patient-and-caregiver-perspectives/index.jsx" /* webpackChunkName: "component---src-pages-pediatric-patients-patient-and-caregiver-perspectives-index-jsx" */),
  "component---src-pages-pediatric-patients-screening-index-jsx": () => import("./../../../src/pages/pediatric-patients/screening/index.jsx" /* webpackChunkName: "component---src-pages-pediatric-patients-screening-index-jsx" */),
  "component---src-pages-pediatric-patients-symptoms-index-jsx": () => import("./../../../src/pages/pediatric-patients/symptoms/index.jsx" /* webpackChunkName: "component---src-pages-pediatric-patients-symptoms-index-jsx" */),
  "component---src-pages-pediatric-recognize-epworth-sleepiness-scale-for-children-and-adolescents-index-jsx": () => import("./../../../src/pages/pediatric/recognize/epworth-sleepiness-scale-for-children-and-adolescents/index.jsx" /* webpackChunkName: "component---src-pages-pediatric-recognize-epworth-sleepiness-scale-for-children-and-adolescents-index-jsx" */),
  "component---src-pages-resources-glossary-of-terms-cataplexy-jsx": () => import("./../../../src/pages/resources/glossary-of-terms/cataplexy.jsx" /* webpackChunkName: "component---src-pages-resources-glossary-of-terms-cataplexy-jsx" */),
  "component---src-pages-resources-glossary-of-terms-chess-jsx": () => import("./../../../src/pages/resources/glossary-of-terms/chess.jsx" /* webpackChunkName: "component---src-pages-resources-glossary-of-terms-chess-jsx" */),
  "component---src-pages-resources-glossary-of-terms-comorbidity-jsx": () => import("./../../../src/pages/resources/glossary-of-terms/comorbidity.jsx" /* webpackChunkName: "component---src-pages-resources-glossary-of-terms-comorbidity-jsx" */),
  "component---src-pages-resources-glossary-of-terms-csf-hypocretin-1-jsx": () => import("./../../../src/pages/resources/glossary-of-terms/csf-hypocretin-1.jsx" /* webpackChunkName: "component---src-pages-resources-glossary-of-terms-csf-hypocretin-1-jsx" */),
  "component---src-pages-resources-glossary-of-terms-epworth-sleepiness-scale-jsx": () => import("./../../../src/pages/resources/glossary-of-terms/epworth-sleepiness-scale.jsx" /* webpackChunkName: "component---src-pages-resources-glossary-of-terms-epworth-sleepiness-scale-jsx" */),
  "component---src-pages-resources-glossary-of-terms-excessive-daytime-sleepiness-jsx": () => import("./../../../src/pages/resources/glossary-of-terms/excessive-daytime-sleepiness.jsx" /* webpackChunkName: "component---src-pages-resources-glossary-of-terms-excessive-daytime-sleepiness-jsx" */),
  "component---src-pages-resources-glossary-of-terms-home-sleep-testing-jsx": () => import("./../../../src/pages/resources/glossary-of-terms/home-sleep-testing.jsx" /* webpackChunkName: "component---src-pages-resources-glossary-of-terms-home-sleep-testing-jsx" */),
  "component---src-pages-resources-glossary-of-terms-hypercholesterolemia-jsx": () => import("./../../../src/pages/resources/glossary-of-terms/hypercholesterolemia.jsx" /* webpackChunkName: "component---src-pages-resources-glossary-of-terms-hypercholesterolemia-jsx" */),
  "component---src-pages-resources-glossary-of-terms-hypertension-jsx": () => import("./../../../src/pages/resources/glossary-of-terms/hypertension.jsx" /* webpackChunkName: "component---src-pages-resources-glossary-of-terms-hypertension-jsx" */),
  "component---src-pages-resources-glossary-of-terms-hypnagogic-hypnopompic-hallucinations-jsx": () => import("./../../../src/pages/resources/glossary-of-terms/hypnagogic-hypnopompic-hallucinations.jsx" /* webpackChunkName: "component---src-pages-resources-glossary-of-terms-hypnagogic-hypnopompic-hallucinations-jsx" */),
  "component---src-pages-resources-glossary-of-terms-hypocretin-neurons-jsx": () => import("./../../../src/pages/resources/glossary-of-terms/hypocretin-neurons.jsx" /* webpackChunkName: "component---src-pages-resources-glossary-of-terms-hypocretin-neurons-jsx" */),
  "component---src-pages-resources-glossary-of-terms-index-js": () => import("./../../../src/pages/resources/glossary-of-terms/index.js" /* webpackChunkName: "component---src-pages-resources-glossary-of-terms-index-js" */),
  "component---src-pages-resources-glossary-of-terms-multiple-sleep-latency-test-jsx": () => import("./../../../src/pages/resources/glossary-of-terms/multiple-sleep-latency-test.jsx" /* webpackChunkName: "component---src-pages-resources-glossary-of-terms-multiple-sleep-latency-test-jsx" */),
  "component---src-pages-resources-glossary-of-terms-myocardial-infarction-jsx": () => import("./../../../src/pages/resources/glossary-of-terms/myocardial-infarction.jsx" /* webpackChunkName: "component---src-pages-resources-glossary-of-terms-myocardial-infarction-jsx" */),
  "component---src-pages-resources-glossary-of-terms-narcolepsy-symptom-screener-jsx": () => import("./../../../src/pages/resources/glossary-of-terms/narcolepsy-symptom-screener.jsx" /* webpackChunkName: "component---src-pages-resources-glossary-of-terms-narcolepsy-symptom-screener-jsx" */),
  "component---src-pages-resources-glossary-of-terms-narcolepsy-type-1-jsx": () => import("./../../../src/pages/resources/glossary-of-terms/narcolepsy-type-1.jsx" /* webpackChunkName: "component---src-pages-resources-glossary-of-terms-narcolepsy-type-1-jsx" */),
  "component---src-pages-resources-glossary-of-terms-narcolepsy-type-2-jsx": () => import("./../../../src/pages/resources/glossary-of-terms/narcolepsy-type-2.jsx" /* webpackChunkName: "component---src-pages-resources-glossary-of-terms-narcolepsy-type-2-jsx" */),
  "component---src-pages-resources-glossary-of-terms-polysomnography-jsx": () => import("./../../../src/pages/resources/glossary-of-terms/polysomnography.jsx" /* webpackChunkName: "component---src-pages-resources-glossary-of-terms-polysomnography-jsx" */),
  "component---src-pages-resources-glossary-of-terms-rapid-eye-movement-rem-sleep-jsx": () => import("./../../../src/pages/resources/glossary-of-terms/rapid-eye-movement-rem-sleep.jsx" /* webpackChunkName: "component---src-pages-resources-glossary-of-terms-rapid-eye-movement-rem-sleep-jsx" */),
  "component---src-pages-resources-glossary-of-terms-sleep-disruption-jsx": () => import("./../../../src/pages/resources/glossary-of-terms/sleep-disruption.jsx" /* webpackChunkName: "component---src-pages-resources-glossary-of-terms-sleep-disruption-jsx" */),
  "component---src-pages-resources-glossary-of-terms-sleep-onset-rem-period-soremp-jsx": () => import("./../../../src/pages/resources/glossary-of-terms/sleep-onset-rem-period-soremp.jsx" /* webpackChunkName: "component---src-pages-resources-glossary-of-terms-sleep-onset-rem-period-soremp-jsx" */),
  "component---src-pages-resources-glossary-of-terms-sleep-paralysis-jsx": () => import("./../../../src/pages/resources/glossary-of-terms/sleep-paralysis.jsx" /* webpackChunkName: "component---src-pages-resources-glossary-of-terms-sleep-paralysis-jsx" */),
  "component---src-pages-resources-glossary-of-terms-swiss-narcolepsy-scale-jsx": () => import("./../../../src/pages/resources/glossary-of-terms/swiss-narcolepsy-scale.jsx" /* webpackChunkName: "component---src-pages-resources-glossary-of-terms-swiss-narcolepsy-scale-jsx" */),
  "component---src-pages-resources-narcolepsy-expert-clinical-insights-index-jsx": () => import("./../../../src/pages/resources/narcolepsy-expert-clinical-insights/index.jsx" /* webpackChunkName: "component---src-pages-resources-narcolepsy-expert-clinical-insights-index-jsx" */),
  "component---src-pages-resources-narcolepsy-faqs-index-jsx": () => import("./../../../src/pages/resources/narcolepsy-faqs/index.jsx" /* webpackChunkName: "component---src-pages-resources-narcolepsy-faqs-index-jsx" */),
  "component---src-pages-resources-resource-center-index-jsx": () => import("./../../../src/pages/resources/resource-center/index.jsx" /* webpackChunkName: "component---src-pages-resources-resource-center-index-jsx" */),
  "component---src-pages-screening-and-diagnosis-cataplexy-patient-testimonials-index-jsx": () => import("./../../../src/pages/screening-and-diagnosis/cataplexy-patient-testimonials/index.jsx" /* webpackChunkName: "component---src-pages-screening-and-diagnosis-cataplexy-patient-testimonials-index-jsx" */),
  "component---src-pages-screening-and-diagnosis-diagnostic-criteria-index-jsx": () => import("./../../../src/pages/screening-and-diagnosis/diagnostic-criteria/index.jsx" /* webpackChunkName: "component---src-pages-screening-and-diagnosis-diagnostic-criteria-index-jsx" */),
  "component---src-pages-screening-and-diagnosis-diagnostic-process-index-jsx": () => import("./../../../src/pages/screening-and-diagnosis/diagnostic-process/index.jsx" /* webpackChunkName: "component---src-pages-screening-and-diagnosis-diagnostic-process-index-jsx" */),
  "component---src-pages-screening-and-diagnosis-epworth-sleepiness-scale-index-jsx": () => import("./../../../src/pages/screening-and-diagnosis/epworth-sleepiness-scale/index.jsx" /* webpackChunkName: "component---src-pages-screening-and-diagnosis-epworth-sleepiness-scale-index-jsx" */),
  "component---src-pages-screening-and-diagnosis-narcolepsy-differential-diagnosis-index-jsx": () => import("./../../../src/pages/screening-and-diagnosis/narcolepsy-differential-diagnosis/index.jsx" /* webpackChunkName: "component---src-pages-screening-and-diagnosis-narcolepsy-differential-diagnosis-index-jsx" */),
  "component---src-pages-screening-and-diagnosis-recognizing-symptoms-index-jsx": () => import("./../../../src/pages/screening-and-diagnosis/recognizing-symptoms/index.jsx" /* webpackChunkName: "component---src-pages-screening-and-diagnosis-recognizing-symptoms-index-jsx" */),
  "component---src-pages-screening-and-diagnosis-screeners-index-jsx": () => import("./../../../src/pages/screening-and-diagnosis/screeners/index.jsx" /* webpackChunkName: "component---src-pages-screening-and-diagnosis-screeners-index-jsx" */),
  "component---src-pages-screening-and-diagnosis-swiss-narcolepsy-scale-index-jsx": () => import("./../../../src/pages/screening-and-diagnosis/swiss-narcolepsy-scale/index.jsx" /* webpackChunkName: "component---src-pages-screening-and-diagnosis-swiss-narcolepsy-scale-index-jsx" */),
  "component---src-pages-sitemap-index-jsx": () => import("./../../../src/pages/sitemap/index.jsx" /* webpackChunkName: "component---src-pages-sitemap-index-jsx" */),
  "component---src-pages-sleep-specialist-network-archived-events-index-jsx": () => import("./../../../src/pages/sleep-specialist-network/archived-events/index.jsx" /* webpackChunkName: "component---src-pages-sleep-specialist-network-archived-events-index-jsx" */),
  "component---src-pages-stay-connected-about-narcolepsy-link-index-jsx": () => import("./../../../src/pages/stay-connected/about-narcolepsy-link/index.jsx" /* webpackChunkName: "component---src-pages-stay-connected-about-narcolepsy-link-index-jsx" */),
  "component---src-pages-stay-connected-connect-with-us-index-jsx": () => import("./../../../src/pages/stay-connected/connect-with-us/index.jsx" /* webpackChunkName: "component---src-pages-stay-connected-connect-with-us-index-jsx" */),
  "component---src-pages-stay-connected-narcolepsy-information-links-index-jsx": () => import("./../../../src/pages/stay-connected/narcolepsy-information-links/index.jsx" /* webpackChunkName: "component---src-pages-stay-connected-narcolepsy-information-links-index-jsx" */),
  "component---src-pages-stay-connected-refer-to-a-sleep-specialist-index-jsx": () => import("./../../../src/pages/stay-connected/refer-to-a-sleep-specialist/index.jsx" /* webpackChunkName: "component---src-pages-stay-connected-refer-to-a-sleep-specialist-index-jsx" */)
}

